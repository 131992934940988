
import * as customCloudflareRuntime$Mss3xNwD3v from '/home/web/wap/_online/vue_src/nuxt/providers/cloudflare/index.ts'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 1024,
    "lg": 1536,
    "xl": 1920,
    "xxl": 1536,
    "2xl": 1536,
    "tb": 768,
    "ml": 1280
  },
  "presets": {},
  "provider": "customCloudflare",
  "domains": [
    "www.weareplaystation.fr"
  ],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['customCloudflare']: { provider: customCloudflareRuntime$Mss3xNwD3v, defaults: {} }
}
        