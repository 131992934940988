import { joinURL, encodeQueryItem } from 'ufo'
import { createOperationsGenerator } from '#image'
import type {
  ProviderGetImage,
  ImageModifiers,
  OperationGeneratorConfig,
} from '@nuxt/image'
import { getURLPathname } from '~/utils/helpers'

// https://github.com/nuxt/image/blob/main/src/runtime/providers/cloudflare.ts
const operationsGenerator = createOperationsGenerator({
  keyMap: {
    width: 'w',
    height: 'h',
    dpr: 'dpr',
    fit: 'fit',
    gravity: 'g',
    quality: 'q',
    format: 'f',
    sharpen: 'sharpen',
  },
  valueMap: {
    fit: {
      cover: 'cover',
      contain: 'contain',
      fill: 'scale-down',
      outside: 'crop',
      inside: 'pad',
    },
    gravity: {
      auto: 'auto',
      side: 'side',
    },
  },
  joinWith: ',',
  formatter: (key, val) => encodeQueryItem(key, val),
} satisfies OperationGeneratorConfig)

const defaultModifiers: Partial<ImageModifiers> = {}

// https://developers.cloudflare.com/images/image-resizing/url-format/
export const getImage: ProviderGetImage = (
  src,
  { modifiers = {}, baseURL = '/' } = {},
) => {
  const mergeModifiers = { ...defaultModifiers, ...modifiers }
  const operations = operationsGenerator(mergeModifiers)

  const source = getURLPathname(src)

  // https://<ZONE>/cdn-cgi/image/<OPTIONS>/<SOURCE-IMAGE>
  const url = operations
    ? joinURL(baseURL, 'cdn-cgi/image', operations, source)
    : joinURL(baseURL, source)

  return {
    url,
  }
}
